const PL = {
  "pl-PL": {
    translation: {
      "test": "ABC",
      "kosten-text": "Wynagrodzeniem jest dla mnie prowizja otrzymywana bezpośrednio od danego ubezpieczyciela za pośrednictwo – tzn. od podpisanej umowy. Jeśli zadają sobie państwo pytanie o zabezpieczenie ryzyk prywatnych i zawodowych, - jest to odpowiednia strona.",
      "kosten-titel": "Mój serwis jest bezpłatny!",
      "sachversicherung": "Ubezpieczenia majątkowe",
      "krankenversicherung": "Ubezpieczenia zdrowotne",
      "personenversicherung": "Ubezpieczenia osobowe",
      "unternehmensversicherung": "Ubezpieczenia dla firm",
      "welcome": "Serdecznie Witam!",
      "versicherungsmaklerin": "Dorota Tworkowski - Broker UBEZPIECZENIOWY",
      "welcome-text1": "Nazywam się Dorota Tworkowski. Od roku 2006 pracuję jako broker ubezpieczeniowy. Jestem niezależna od jakiejkolwiek firmy ubezpieczeniowej. Pracuję na zlecenie i w imieniu zleceniodawcy (czyli klienta). Moje porady są objektywne i nastawione na realizację życzeń i potrzeb klienta.",
      "welcome-text2": "Moim zadaniem jest również odradzenie Państwu zbędnych ubezpieczeń. Mogę zaoferować państwu całą paletę ubezpieczeń dostępnych na rynku niemieckim.",
      "welcome-text3": "Wybór ubezpieczenia jest ustalany indywidualnie. Podczas rozmowy, do której jestem zobowiązana przepisami, omawiane są najlepsze rozwiązania dla danej osoby. Ponieważ jestem niezależnym specjalistą ubezpieczeniowym, tzn. nie związanym z żadnym z wymienionych na mojej stronie ubezpieczycielem, wybieram wraz z Państwem najbardziej optymalne ubezpieczenia.",
      "kontakt-aufnehmen": "Skontaktuj się ze mną",
      "sende": "wyślij",
      "name": "Imię i nazwisko",
      "nachricht": "Wiadomość",
      "ERR_MAIL_VALUES_EMPTY": "Pola nie mogą być puste!",
      "ERR_MAIL_NOT_SENT": "Wiadomość nie mogła zostać wysłana.",
      "SUCC_MAIL_SENT": "Wiadomość została wysłana.",
      "teilen": "Udostępnij linki na Social Media",
      "kontaktformular": "Formularz kontaktowy",
      "versicherungen": "Ubezpieczenia",

      "cookie_consent_title": "Prywatność użytkowników",
      "cookie_consent_message": "W celu zbierania różnych statystyk dla tej strony, potrzebujemy Twojej zgody na przechowywanie plików cookie na Twoim urządzeniu.",
      "cookie_consent_agree": "Uzgodnij i kontynuuj",
      "cookie_consent_disagree": "Nie, dziękuję",
      "cookie_consent_more_info": "Więcej informacji pod",

      "CAPTCHA_INFO_TEXT": "Potwierdź, aby zakończyć działanie.",
      "CAPTCHA_MESSAGE_ERR": "Uwierzytelnianie przez CAPTCHA nie udało się"
    }
  }
};

export default PL;