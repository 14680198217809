const DE = {
  "de-DE": {
    translation: {
      "test": "TEST !23",
      "kosten-text": "Meine Vergütung ist die Provision, die ich für die Vermittlung direkt von dem jeweiligen Versicherer erhalte - also auf den abgeschlossenen Vertrag. Wenn Sie Fragen zur Versicherung von privaten und beruflichen Risiken haben, sind Sie hier richtig.",
      "kosten-titel": "Mein Dienst ist kostenlos!",
      "sachversicherung": "Sachversicherung",
      "krankenversicherung": "Krankenversicherung",
      "personenversicherung": "Personenversicherung",
      "unternehmensversicherung": "Versicherungen für Unternehmen",
      "welcome": "Herzlich Willkommen!",
      "versicherungsmaklerin": "Dorota Tworkowski - Versicherungsmaklerin",
      "welcome-text1": "Mein name ist Dorota Tworkowski. Ich bin seit 2006 als Versicherungsmakler tätig und agiere unabhängig von jeder Versicherungsgesellschaft. Ich arbeite im Auftrag und im Namen des Auftraggebers (d.h. des Kunden). Meine Beratung ist objektiv und orientiert sich an den Wünschen und Bedürfnissen des Kunden.",
      "welcome-text2": "Meine Aufgabe ist es auch, Sie von unnötigen Versicherungen abzubringen. Ich kann Ihnen die gesamte Palette der auf dem deutschen Markt verfügbaren Versicherungen anbieten.",
      "welcome-text3": "Die Wahl der Versicherung wird individuell getroffen. Bei einem Gespräch, zu dem ich gesetzlich verpflichtet bin, werden die besten Lösungen für die betreffende Person erörtert. Da ich ein unabhängiger Versicherungsspezialist bin, d.h. ich stehe in keiner Verbindung zu einem der auf meiner Website aufgeführten Versicherer, wähle ich gemeinsam mit Ihnen die optimale Versicherung aus.",
      "kontakt-aufnehmen": "Kontakt aufnehmen",
      "sende": "Sende Nachricht",
      "name": "Name",
      "nachricht": "Nachricht",
      "ERR_MAIL_VALUES_EMPTY": "Felder dürfen nicht leer sein!",
      "ERR_MAIL_NOT_SENT": "Nachricht konnte nicht versendet werden.",
      "SUCC_MAIL_SENT": "Nachricht erfolgreich versendet.",
      "teilen": "Auf Social Media teilen",
      "kontaktformular": "Kontaktformular",
      "versicherungen": "Versicherungen",

      "cookie_consent_title": "Ihre Privatsphäre",
      "cookie_consent_message": "Um diverse Statistiken für diese Seite sammeln zu können, benötigen wir Ihre Einverständis zum Speichern von Cookies auf Ihrem Gerät.",
      "cookie_consent_agree": "Zustimmen & Weiter",
      "cookie_consent_disagree": "Ablehnen",
      "cookie_consent_more_info": "Mehr Infos unter",

      "CAPTCHA_INFO_TEXT": "Bitte bestätigen, um Aktion abzuschließen.",
      "CAPTCHA_MESSAGE_ERR": "Authentifizierung über CAPTCHA fehlgeschlagen."
    }
  }
};

export default DE;