const ENVIRONMENT = {
  LOCATION: process.env.NODE_ENV,
  EMAILJS_SERVICE_ID: process.env.EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID: process.env.EMAILJS_TEMPLATE_ID,
  EMAILJS_USER_ID: process.env.EMAILJS_USER_ID,
  EMAILJS_RECEIVER_MAIL: process.env.EMAILJS_RECEIVER_MAIL,
  GOOGLE_RECAPTCHA_WEBSITE_KEY: process.env.GOOGLE_RECAPTCHA_WEBSITE_KEY,
  GOOGLE_ANALYTICS_TRACKER_ID: process.env.GOOGLE_ANALYTICS_TRACKER_ID,
  CONTENTFUL_SPACE_ACCESS_TOKEN: process.env.CONTENTFUL_SPACE_ACCESS_TOKEN,
};

export default ENVIRONMENT;
