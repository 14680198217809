import DE from "./locales/de";
import PL from "./locales/pl";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const i18nextConfig = {
  resources: {
    ...DE,
    ...PL,
  },
  //lng: "pl-PL", // don't use if languageDetector is used because of overwrite
  fallbackLng: "pl-PL",
  ns: ["translation"],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nextConfig);

export default i18next;

export const setRuntimeTranslation = (language, runTimeData) => {
  i18next.addResources(language, i18nextConfig.ns[0], runTimeData);
};

export const reloadTranslations = () => {
  i18next.changeLanguage(i18next.language);
};
