import React, { Suspense, useEffect, createContext, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ENVIRONMENT from "../../utils/environment";
import { reloadTranslations, setRuntimeTranslation } from "../../i18n";
import { CubeSpinner } from "../../components/spinners";

const Home = React.lazy(() => import("../../pages/home"));
const Impressum = React.lazy(() => import("../../pages/impressum"));
const Contact = React.lazy(() => import("../../pages/contact"));
const Datenschutz = React.lazy(() => import("../../pages/datenschutz"));

const RuntimeContext = createContext();
const RuntimeContextProvider = (props) => {
  const {
    data: translationData,
    isLoading: isLoadingTranslationData,
    isError: isTranslationDataError,
  } = useQuery(["FETCH_TRANSLATIONS"], () =>
    fetch(
      `https://cdn.contentful.com/spaces/oahq6kw11j5e/entries?content_type=translationResource&access_token=${ENVIRONMENT.CONTENTFUL_SPACE_ACCESS_TOKEN}`
    ).then((res) => res.json())
  );

  const {
    data: assetData,
    isLoading: isLoadingAssetData,
    isError: isAssetDataError,
  } = useQuery(["FETCH_ASSETS"], () =>
    fetch(
      `https://cdn.contentful.com/spaces/oahq6kw11j5e/assets?access_token=${ENVIRONMENT.CONTENTFUL_SPACE_ACCESS_TOKEN}`
    ).then((res) => res.json())
  );

  /* const {
    data: avatarImage,
    isLoading: isLoadingAvatarImage,
    isError: isAvatarImageError,
  } = useQuery(
    "FETCH_AVATAR_IMAGE",
    () =>
      fetch(
        "https://images.ctfassets.net/oahq6kw11j5e/2pDu2m8SvIEO3KdH1Vnyii/ea877354d210ebe4f249dc01e0e42746/avatar.png?fm=webp&q=50"
      ).then((res) => res.json()),
    { refetchOnWindowFocus: false, refetchOnMount: true }
  );

  const {
    data: backgroundImage,
    isLoading: isLoadingBackgroundImage,
    isError: isBackgroundImageError,
  } = useQuery(
    "FETCH_BACKGROUND_IMAGE",
    () =>
      fetch(
        "https://images.ctfassets.net/oahq6kw11j5e/6ErK7z9x5rolWqOS2aETw9/6c7830ee82c3b61ff1d10bb8b998a666/background.jpg?fm=webp&q=50"
      ).then((res) => res.json()),
    { refetchOnWindowFocus: false, refetchOnMount: true }
  ); */

  useEffect(() => {
    if (!translationData) return;

    // verification
    if (!translationData) return;
    if (!translationData?.items) return;
    translationData.items.forEach((e) => {
      const fields = e?.fields;
      setRuntimeTranslation(fields?.resourceId, fields?.resourceJson);
    });
    reloadTranslations();
  }, [translationData]);

  const backgroundImage = useMemo(() => {
    if (!assetData) return null;
    const items = assetData?.items;
    if (!items) return null;
    const selectedItems = items.filter(
      (e) => e?.fields?.title === "background"
    );
    if (selectedItems.length == 0) return null;
    const rawUrl = selectedItems[0]?.fields?.file?.url;
    if (!rawUrl) return null;
    const url = new URL(`https:${rawUrl}`);
    url.searchParams.set("fm", "webp"); // file format
    url.searchParams.set("q", 50); // quality (1-100)
    return url.href;
  }, [assetData]);

  const avatarImage = useMemo(() => {
    if (!assetData) return null;
    const items = assetData?.items;
    if (!items) return null;
    const selectedItems = items.filter((e) => e?.fields?.title === "avatar");
    if (selectedItems.length == 0) return null;
    const rawUrl = selectedItems[0]?.fields?.file?.url;
    if (!rawUrl) return null;
    const url = new URL(`https:${rawUrl}`);
    url.searchParams.set("fm", "webp"); // file format
    url.searchParams.set("q", 30); // quality (1-100)
    return url.href;
  }, [assetData]);

  return (
    <RuntimeContext.Provider
      value={{
        isLoadingTranslationData,
        isTranslationDataError,
        isLoadingAssetData,
        isAssetDataError,
        backgroundImage,
        avatarImage,
      }}
    >
      {props.children}
    </RuntimeContext.Provider>
  );
};

const PageRouter = (props) => {
  return (
    <Switch>
      <Route exact path="/impressum">
        <Suspense fallback={<CubeSpinner />}>
          <Impressum />
        </Suspense>
      </Route>
      <Route exact path="/kontakt">
        <Suspense fallback={<CubeSpinner />}>
          <Contact />
        </Suspense>
      </Route>
      <Route exact path="/datenschutz">
        <Suspense fallback={<CubeSpinner />}>
          <Datenschutz />
        </Suspense>
      </Route>
      <Route exact path="/">
        <Suspense fallback={<CubeSpinner />}>
          <Home />
        </Suspense>
      </Route>
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default PageRouter;
export { RuntimeContext, RuntimeContextProvider };
